import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IndexComponent } from "./index/index.component";
import { ListComponent } from "./list/list.component";

const routes: Routes = [
  {
    path: "",
    data: { breadcrumb: "Delivery Obligations" },
    component: IndexComponent,
    children: [
      {
        path: "",
        data: { breadcrumb: "" },
        component: ListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KeyTermsRoutingModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyTermsRoutingModule } from './key-terms-routing.module';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ListComponent } from './list/list.component';


@NgModule({
  declarations: [
    IndexComponent,
    ListComponent
  ],
  imports: [
    CommonModule,
    KeyTermsRoutingModule,
    SharedModule,
    AngularEditorModule,
    MaterialmodulesModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxPermissionsModule
  ]
})
export class KeyTermsModule { }

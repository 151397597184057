import { Component, OnInit } from '@angular/core';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {TermsService} from  'src/app/shared/services/terms.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  createForm: FormArray | any;
  deleted_category:any = [];
  deleted_subcategory:any = [];
  deleted_terms:any = [];

  //MOS 
  formulaList:any = [];
  unitList:any = [];
  functionList:any = [];
  filteredMosList:any = [];
  mosList:any = [];
  editItem:boolean = false ;
  mosSelectedId:any;
  calculation_mos:any;
  unit:any;
  MosCount:any;
  selectedTermId:any;
  mosInput:any;
  mosInputTarget:any;
  evidenceList:any
  constructor(
    private fb: FormBuilder,
    private permissionsService: NgxPermissionsService,
    private termService : TermsService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.createForm = this.fb.array([]);
    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem('permission') || '{}').permissions);
    this.createForm = this.fb.array([]);
    this.getMaster();
    this.getMos();
    this.getUnitList();
    this.getEvidenceList();
    this.getFormulaList();
    this.getFunctionValues();
  }

  getMaster(){
    this.spinner.show();
    this.termService.getTermsMaster().subscribe((res: any) => {
      res?.terms_hierarchy?.forEach((obj: any) => {
        obj.subcategories = obj.subcategories;
        this.createForm.value.push(obj);
      });
      this.spinner.hide();
    })
  }

  addCategoryItems(){
    this.createForm.value.push({
      name: "",
      prefix: "",
      impact: ""
    })
    this.edit(this.createForm.value.length - 1)
  }
  addSubcategoryItems(i: any){
    if(!this.createForm.value[i].subcategories){
      this.createForm.value[i].subcategories = [];
    }
    
    this.createForm.value[i].subcategories.push({
      name: "",
      prefix: "",
      impact: ""
    })

    this.edit(i, this.createForm.value[i].subcategories.length - 1)
  }

  addTermsItems(i: any, j: any){
    if(!this.createForm.value[i].subcategories[j].terms){
      this.createForm.value[i].subcategories[j].terms = [];
    }
   
    this.createForm.value[i].subcategories[j].terms.push({
      title: "",
      prefix: "",
      impact: ""
    })

    this.edit(i, j, this.createForm.value[i].subcategories[j].terms.length - 1)
  }

  edit(i: any = undefined, j: any = undefined, k: any = undefined){
    this.createForm.value.forEach((iind: any) => {
      iind.edit = false;
      iind?.subcategories?.forEach((krind: any) => {
        krind.edit = false;
        krind?.terms?.forEach((goind: any) => {
          goind.edit = false;
        });
      });
    });
    if(k != undefined){
      this.createForm.value[i].subcategories[j].terms[k].edit = true;
      this.createForm.value[i].subcategories[j].terms[k].edit_name = this.createForm.value[i].subcategories[j].terms[k].title;
      this.createForm.value[i].subcategories[j].terms[k].edit_function = this.createForm.value[i].subcategories[j].terms[k].function?.id,
      this.createForm.value[i].subcategories[j].terms[k].edit_fulfilment_by = this.createForm.value[i].subcategories[j].terms[k].fulfilment_by,
      this.createForm.value[i].subcategories[j].terms[k].edit_evidence = this.createForm.value[i].subcategories[j].terms[k].evidence?.id,
      this.createForm.value[i].subcategories[j].terms[k].edit_metrics = this.createForm.value[i].subcategories[j].terms[k].metrics,
      this.createForm.value[i].subcategories[j].terms[k].edit_tag_ids = this.createForm.value[i].subcategories[j].terms[k].tag_ids,
      this.createForm.value[i].subcategories[j].terms[k].edit_domain = this.createForm.value[i].subcategories[j].terms[k].domain,
      this.createForm.value[i].subcategories[j].terms[k].edit_defination_of_done = this.createForm.value[i].subcategories[j].terms[k].defination_of_done
    
    }else if(j != undefined){
      this.createForm.value[i].subcategories[j].edit = true;
      this.createForm.value[i].subcategories[j].edit_name = this.createForm.value[i].subcategories[j].name;
    }else{
      this.createForm.value[i].edit = true;
      this.createForm.value[i].edit_name = this.createForm.value[i].name;
    }
    
  }

  update(i: any = undefined, j:any = undefined, k:any = undefined){
    this.createForm.value.forEach((iind: any) => {
      iind.edit = false;
      iind?.subcategories?.forEach((krind: any) => {
        krind.edit = false;
        krind?.terms?.forEach((goind: any) => {
          goind.edit = false;
        });
      });
    });
    if(k != undefined){
      this.createForm.value[i].subcategories[j].terms[k].edit = false;
      this.createForm.value[i].subcategories[j].terms[k].title = this.createForm.value[i].subcategories[j].terms[k].edit_name;
      this.createForm.value[i].subcategories[j].terms[k].function_id = this.createForm.value[i].subcategories[j].terms[k].edit_function,
      this.createForm.value[i].subcategories[j].terms[k].fulfilment_by = this.createForm.value[i].subcategories[j].terms[k].edit_fulfilment_by,
      this.createForm.value[i].subcategories[j].terms[k].evidence_id = this.createForm.value[i].subcategories[j].terms[k].edit_evidence,
      this.createForm.value[i].subcategories[j].terms[k].metrics = this.createForm.value[i].subcategories[j].terms[k].edit_metrics,
      this.createForm.value[i].subcategories[j].terms[k].tag_ids = this.createForm.value[i].subcategories[j].terms[k].edit_tag_ids,
      this.createForm.value[i].subcategories[j].terms[k].domain = this.createForm.value[i].subcategories[j].terms[k].edit_domain,
      this.createForm.value[i].subcategories[j].terms[k].defination_of_done = this.createForm.value[i].subcategories[j].terms[k].edit_defination_of_done
    }else if(j != undefined){
      this.createForm.value[i].subcategories[j].edit = false;
      this.createForm.value[i].subcategories[j].name = this.createForm.value[i].subcategories[j].edit_name;
    }else{
      this.createForm.value[i].edit = false;
      this.createForm.value[i].name = this.createForm.value[i].edit_name;
    }
  }
  removeCategory(i: any){
    if(this.createForm.value[i].id){
      
      this.deleted_category.push(this.createForm.value[i].id);
    }
    this.createForm.value.splice(i, 1)
  }
  removeSubcategory(i: any, j: any){
    if(this.createForm.value[i].subcategories[j].id){
      this.deleted_subcategory.push(this.createForm.value[i].subcategories[j].id);
    }
    this.createForm.value[i].subcategories.splice(j, 1)
  }

  removeTerms(i: any, j: any, k: any){
    if(this.createForm.value[i].subcategories[j].terms[k].id){
      this.deleted_terms.push(this.createForm.value[i].subcategories[j].terms[k].id);
    }
    this.createForm.value[i].subcategories[j].terms.splice(k, 1)
  }


  saveKeyTerms(){
    let payload = {
      categories: this.createForm.value,
      deleted_categories: this.deleted_category,
      deleted_subcategories: this.deleted_subcategory,
      deleted_terms: this.deleted_terms
    }
    this.spinner.show();
    this.termService.updateTerms(payload).subscribe((res: any) => {
      this.createForm = this.fb.array([]);
      this.getMaster();
      this.toastrService.success("Terms updated")
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "term"
    }
  }

  cancel(i: any, j: any, k: any){
    this.createForm.value.forEach((iind: any) => {
      iind.edit = false;
      iind?.subcategories?.forEach((krind: any) => {
        krind.edit = false;
        krind?.terms?.forEach((goind: any) => {
          goind.edit = false;
        });
      });
    });
  }

  async getFunctionValues(){
    await this.termService.getBaseValue('delivery_obligation_function').subscribe((res: any) => {
      this.functionList = res;
    })
  }

  async getFormulaList(){
    await this.termService.getBaseValue('mos_formula').subscribe((res: any) => {
       this.formulaList = res;
     })
   }
 
   async getUnitList(){
    await this.termService.getBaseValue('mos_unit').subscribe((res: any) => {
       this.unitList = res;
     })
   }
   async getEvidenceList(){
    await this.termService.getBaseValue('delivery_obligation_evidence').subscribe((res: any) => {
       this.evidenceList = res;
     })
   }
   async getMos(){
   await this.termService.getMosMaster().subscribe((res: any) => {
      this.mosList = res;
      this.filteredMosList = this.mosList;
    })
  }
  removeMOS(i:any,j:any,k:any,mos:any){
    this.termService.deleteMosValue(mos.id).subscribe((res: any) => {
      const index = this.createForm.value[i].subcategories[j].terms[k].instancemetrics.indexOf(mos.id);
      this.createForm.value[i].subcategories[j].terms[k].instancemetrics.splice(index, 1);
    
    })
  }
   setMosCount(count:number,term_id:any){
    this.filteredMosList = this.mosList
    this.editItem = false;
    this.mosSelectedId = "";
    this.calculation_mos = "";
    this.unit = "";
    this.editItem = false;
    this.MosCount = count + 1;
    this.selectedTermId = term_id;
  }
  
  createMos(i: any, j: any, k: any){
    this.spinner.show();
    let payload:any;
    if(this.mosSelectedId){
     payload = {
      name: this.mosInput,
      display_name: this.mosInput,
      target_value: this.mosInputTarget,
      metrics_calculation_id:parseInt(this.calculation_mos),
      metrics_unit_id:parseInt(this.unit),
      term_id:this.selectedTermId,
      instancemetrics_id:this.mosSelectedId
     }
    }else{
      payload = {
        name: this.mosInput,
        display_name: this.mosInput,
        target_value: this.mosInputTarget,
        metrics_calculation_id:parseInt(this.calculation_mos),
        metrics_unit_id:parseInt(this.unit),
        term_id:this.selectedTermId,
    }
  }

    this.termService.createMosValue(payload, this.mosSelectedId).subscribe((res: any) => {
     
      this.mosInput = "";
      this.mosInputTarget = "";
        this.mosSelectedId = "";
        this.calculation_mos = "";
        this.unit = "";
        this.editItem = false;
        this.createForm.value[i].subcategories[j].terms[k].instancemetrics = this.createForm.value[i].subcategories[j].terms[k].instancemetrics.filter((e: any) => e.id !== res?.id)
        let json =  {
            id:res?.id,
            instancemetrics:res?.instancemetrics?.id,
            instancemetrics__metrics_calculation:res?.instancemetrics?.metrics_calculation?.id,
            instancemetrics__metrics_calculation__name:res?.instancemetrics?.metrics_calculation?.name,
            instancemetrics__metrics_unit:res?.instancemetrics?.metrics_unit?.id,
            instancemetrics__metrics_unit__name:res?.instancemetrics?.metrics_unit?.name,
            instancemetrics__name:res?.instancemetrics?.name,
            target_value:res?.target_value
          }
        this.createForm.value[i].subcategories[j].terms[k].instancemetrics.push(json)
      this.searchInput("");
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  public searchInput(val: any) {
    const filterValue = val.toLowerCase();
    this.filteredMosList = this.mosList?.filter((option: any) => option?.name?.toLowerCase().indexOf(filterValue) === 0);
   
  }
  editMos(item: any){
    this.mosInput = item.name;
    this.mosInputTarget = item?.target_value;
    this.calculation_mos = parseInt(item.metrics_calculation);
    this.unit = parseInt(item.metrics_unit);
    this.mosSelectedId = parseInt(item.id);
    this.editItem = true;
   
  }
}

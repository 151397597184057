<h6>{{ staticText.menu.key_terms }}</h6>
<form [formGroup]="createForm">
  <div class="accordion keyTerms" id="accordionExample">
    <div
      class="accordion-item"
      *ngFor="let category of createForm?.value; let i = index"
    >
      <h2 class="accordion-header" id="heading{{ i }}">
        <button
          class="accordion-button collapsed"
          *ngIf="!category.edit"
          type="button"
          data-bs-toggle="collapse"
          [attr.data-bs-target]="'#collapse' + i"
          aria-expanded="false"
          [attr.aria-controls]="'collapse' + i"
        >
          <span
            ><b>{{ category.name }}</b>
          </span>
        </button>
        <span *ngIf="!category.edit" class="edit-icon">
          <i
            class="fa fa-edit"
            style="font-size: 13px; margin-bottom: 5px"
            (click)="edit(i); $event.preventDefault(); $event.stopPropagation()"
          ></i>
          <img
            src="./assets/images/comment-delete.svg"
            (click)="removeCategory(i)"
            style="width: 12px"
          />
        </span>
        <div class="p-3" *ngIf="category.edit">
          <mat-form-field appearance="outline" class="w-75">
            <mat-label class="label-design"></mat-label>
            <input
              type="text"
              matInput
              fullWidth
              status="basic"
              placeholder="Category name"
              [(ngModel)]="category.edit_name"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
          <div class="row mt-2">
            <div
              class="col-md-4 ml-auto"
              style="display: flex; align-item: center; justify-content: end"
            >
              <button
                (click)="cancel(i)"
                mat-stroked-button
                color="primary"
                class="mx-1"
              >
                Cancel
              </button>
              <button
                (click)="update(i)"
                mat-flat-button
                mat-button
                class="bg-primary text-white mx-1"
                color="primary"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </h2>
      <div
        id="collapse{{ i }}"
        class="accordion-collapse collapse"
        [attr.aria-labelledby]="'heading' + i"
        [attr.data-bs-parent]="'#accordionExample'"
      >
        <div class="accordion-body">
          <div class="accordion" id="accordion{{ i }}">
            <div
              class="accordion-item"
              *ngFor="let sub of category?.subcategories; let j = index"
            >
              <h2 class="accordion-header" id="heading{{ i }}{{ j }}">
                <button
                  class="accordion-button collapsed"
                  *ngIf="!sub.edit"
                  type="button"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#collapse' + i + j"
                  aria-expanded="false"
                  [attr.aria-controls]="'collapse' + i + j"
                >
                  <span
                    ><b>{{ sub.name }}</b>
                  </span>
                </button>
                <span *ngIf="!sub.edit" class="edit-icon">
                  <i
                    class="fa fa-edit"
                    style="font-size: 13px; margin-bottom: 5px"
                    (click)="
                      edit(i, j);
                      $event.preventDefault();
                      $event.stopPropagation()
                    "
                  ></i>
                  <img
                    src="./assets/images/comment-delete.svg"
                    (click)="removeSubcategory(i, j)"
                    style="width: 12px"
                  />
                </span>
                <div class="p-3" *ngIf="sub.edit">
                  <mat-form-field appearance="outline" class="w-75">
                    <mat-label class="label-design"></mat-label>
                    <input
                      type="text"
                      matInput
                      fullWidth
                      status="basic"
                      placeholder="Sub Category name"
                      [(ngModel)]="sub.edit_name"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </mat-form-field>

                  <div class="row mt-2">
                    <div
                      class="col-md-4 ml-auto"
                      style="
                        display: flex;
                        align-item: center;
                        justify-content: end;
                      "
                    >
                      <button
                        (click)="cancel(i, j)"
                        mat-stroked-button
                        color="primary"
                        class="mx-1"
                      >
                        Cancel
                      </button>
                      <button
                        (click)="update(i, j)"
                        mat-flat-button
                        mat-button
                        class="bg-primary text-white mx-1"
                        color="primary"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </h2>
              <div
                id="collapse{{ i }}{{ j }}"
                class="accordion-collapse collapse"
                [attr.aria-labelledby]="'heading' + i + j"
                [attr.data-bs-parent]="'#accordion' + i"
              >
                <div class="accordion-body">
                  <div
                    *ngFor="let term of sub?.terms; let k = index"
                    style="
                      padding: 10px;
                      position: relative;
                      background: #f2f2f2;
                      border-radius: 2px;
                      margin-bottom: 10px;
                    "
                  >
                    <div *ngIf="!term.edit">
                      <span style="display: block; font-size: 13px"
                        ><b>{{ term.title }}</b></span
                      >
                      <div style="font-size: 14px; margin-top: 5px">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row" *ngIf="term?.function">
                              <div class="col-md-4">
                                <b>Function :</b>
                              </div>
                              <div class="col-md-8">
                                {{ term?.function?.display_name }}
                              </div>
                            </div>
                            <div class="row" *ngIf="term?.fulfilment_by">
                              <div class="col-md-4">
                                <b>Fulfilled By :</b>
                              </div>
                              <div class="col-md-8">
                                {{ term?.fulfilment_by }}
                              </div>
                            </div>
                            <div class="row" *ngIf="term?.evidence">
                              <div class="col-md-4">
                                <b>Evidence :</b>
                              </div>
                              <div class="col-md-6">
                                {{ term?.evidence?.display_name }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row" *ngIf="term?.domain">
                              <div class="col-md-6">
                                <b>Domain :</b>
                              </div>
                              <div class="col-md-6">
                                {{ term?.domain }}
                              </div>
                            </div>
                            <div class="row" *ngIf="term?.defination_of_done">
                              <div class="col-md-6">
                                <b>Definition of done :</b>
                              </div>
                              <div class="col-md-6">
                                {{ term?.defination_of_done }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="background-color: #e0dbdb; padding: 4px">
                        <p
                          class="mt-2 ml-1 mb-1"
                          style="
                            font-size: 12px;
                            font-weight: 500;
                            text-decoration: underline;
                          "
                        >
                          Metrics/KPI
                        </p>
                        <mat-chip-set
                          *ngFor="
                            let mositem of term.instancemetrics;
                            let y = index
                          "
                        >
                          <span style="font-size: 12px">{{ y + 1 }}. </span>
                          <mat-chip>
                            {{ mositem.instancemetrics__name }}
                            <span class="tgt"
                              >Target: {{ mositem?.target_value }}</span
                            >
                            <span
                              class="tgt"
                              *ngIf="
                                mositem.instancemetrics__metrics_calculation__name
                              "
                              >Formula:
                              {{
                                mositem?.instancemetrics__metrics_calculation__name
                              }}</span
                            >
                            <span
                              class="tgt"
                              *ngIf="
                                mositem.instancemetrics__metrics_unit__name
                              "
                              >Unit:
                              {{
                                mositem?.instancemetrics__metrics_unit__name
                              }}</span
                            > </mat-chip
                          ><br />
                        </mat-chip-set>
                      </div>

                      <div *ngIf="term.id">
                        <hr />
                        <app-tag
                          [dataObj]="getTagObject(term.id)"
                          [tagsIds]="term?.tag_ids"
                        ></app-tag>
                      </div>
                    </div>

                    <span *ngIf="!term.edit" class="edit-icon">
                      <i
                        class="fa fa-edit"
                        style="font-size: 13px; margin-bottom: 5px"
                        (click)="
                          edit(i, j, k);
                          $event.preventDefault();
                          $event.stopPropagation()
                        "
                      ></i>
                      <img
                        src="./assets/images/comment-delete.svg"
                        (click)="removeTerms(i, j, k)"
                        style="width: 12px"
                      />
                    </span>

                    <div class="p-3" *ngIf="term.edit">
                      <mat-form-field
                        appearance="outline"
                        class="w-100 pr-2 pl-2"
                      >
                        <mat-label class="label-design"></mat-label>
                        <input
                          type="text"
                          matInput
                          fullWidth
                          status="basic"
                          placeholder="Term Name"
                          [(ngModel)]="term.edit_name"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </mat-form-field>
                      <mat-form-field
                        appearance="outline"
                        class="w-50 pr-2 pl-2"
                      >
                        <mat-label>Function</mat-label>
                        <mat-select
                          placeholder="Function"
                          [(ngModel)]="term.edit_function"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <mat-option
                            *ngFor="let fun of functionList"
                            [value]="fun.id"
                            >{{ fun?.display_name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field
                        appearance="outline"
                        class="w-50 pr-2 pl-2"
                      >
                        <mat-label>Fulfilled By</mat-label>
                        <mat-select
                          [(ngModel)]="term.edit_fulfilment_by"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <mat-option value="Enterprise">Enterprise</mat-option>
                          <mat-option value="Vendor">Vendor</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field
                        appearance="outline"
                        class="w-50 pr-2 pl-2"
                      >
                        <mat-label>Evidence</mat-label>
                        <mat-select
                          placeholder="Function"
                          [(ngModel)]="term.edit_evidence"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <mat-option
                            *ngFor="let evd of evidenceList"
                            [value]="evd.id"
                            >{{ evd?.display_name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field
                        appearance="outline"
                        class="w-50 pr-2 pl-2"
                      >
                        <mat-label>Domain</mat-label>
                        <input
                          type="text"
                          matInput
                          fullWidth
                          status="basic"
                          placeholder="Domain"
                          [(ngModel)]="term.edit_domain"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </mat-form-field>

                      <mat-form-field
                        appearance="outline"
                        class="w-50 pr-2 pl-2"
                      >
                        <mat-label>Definition of done</mat-label>
                        <input
                          type="text"
                          matInput
                          fullWidth
                          status="basic"
                          placeholder="Defination of done"
                          [(ngModel)]="term.edit_defination_of_done"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </mat-form-field>
                      <p
                        class="mt-2 ml-1 mb-1"
                        *ngIf="term?.id"
                        style="font-size: 12px; font-weight: 500"
                      >
                        Metrics/KPI
                        <span
                          [matMenuTriggerFor]="tagMenu"
                          class="add-bn"
                          (click)="
                            setMosCount(term?.instancemetrics?.length, term?.id)
                          "
                          >+</span
                        >
                      </p>
                      <mat-chip-set
                        aria-label="Measure of Success"
                        *ngFor="
                          let mositem of term.instancemetrics;
                          let x = index
                        "
                      >
                        <span style="font-size: 12px">{{ x + 1 }}. </span>
                        <mat-chip>
                          {{ mositem.instancemetrics__name }}
                          <span class="tgt"
                            >Target: {{ mositem?.target_value }}</span
                          >
                          <span
                            class="tgt"
                            *ngIf="
                              mositem.instancemetrics__metrics_calculation__name
                            "
                            >Formula:
                            {{
                              mositem?.instancemetrics__metrics_calculation__name
                            }}</span
                          >
                          <span
                            class="tgt"
                            *ngIf="mositem.instancemetrics__metrics_unit__name"
                            >Unit:
                            {{
                              mositem?.instancemetrics__metrics_unit__name
                            }}</span
                          >
                          <mat-icon
                            title="Delete"
                            (click)="removeMOS(i, j, k, mositem)"
                            style="
                              color: red;
                              font-size: 16px;
                              margin: 4px 0 0 4px;
                            "
                            >delete</mat-icon
                          > </mat-chip
                        ><br />
                      </mat-chip-set>

                      <mat-menu #tagMenu="matMenu">
                        <div
                          class="tag-pop"
                          style="height: auto; max-height: 300px"
                        >
                          <div class="search-input-holder">
                            <span class="mr-2">{{ MosCount }}. </span>
                            <input
                              type="text"
                              class="search-key"
                              placeholder="Search or Create new"
                              matInput
                              [(ngModel)]="mosInput"
                              (ngModelChange)="searchInput($event)"
                              [ngModelOptions]="{ standalone: true }"
                              (click)="$event.stopPropagation()"
                            />
                          </div>
                          <div
                            style="height: auto; overflow: auto"
                            *ngIf="!editItem"
                          >
                            <ul class="p-0" *ngIf="filteredMosList?.length > 0">
                              <li class="tag-item px-1">
                                <div class="row mx-1">
                                  <div class="col-sm-7">
                                    <p
                                      class="m-0"
                                      style="font-size: 12px; font-weight: 500"
                                    >
                                      MOS
                                    </p>
                                  </div>
                                  <div class="col-sm-3 text-center">
                                    <p
                                      class="m-0"
                                      style="font-size: 12px; font-weight: 500"
                                    >
                                      Formula
                                    </p>
                                  </div>
                                  <div class="col-sm-2 text-center">
                                    <p
                                      class="m-0"
                                      style="font-size: 12px; font-weight: 500"
                                    >
                                      Unit
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li
                                *ngFor="
                                  let item of filteredMosList;
                                  let a = index
                                "
                                class="tag-item px-1"
                                (click)="
                                  editMos(item); $event.stopPropagation()
                                "
                              >
                                <div class="row mx-1">
                                  <div
                                    class="col-sm-7"
                                    style="word-wrap: break-word"
                                  >
                                    {{ item.display_name || item.name }}
                                  </div>
                                  <div class="col-sm-3">
                                    {{ item?.metrics_calculation__name }}
                                  </div>
                                  <div class="col-sm-2">
                                    {{ item?.metrics_unit__name }}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div
                            *ngIf="filteredMosList?.length == 0 || editItem"
                            class="mt-2 mb-2 tag-not-found"
                          >
                            <div class="row mx-2 mt-2">
                              <div
                                class="col-sm-4"
                                style="display: flex; align-items: center"
                              >
                                <label class="m-0">MOS Title</label>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  type="text"
                                  class="form-control"
                                  [(ngModel)]="mosInput"
                                  style="font-size: 12px"
                                  [ngModelOptions]="{ standalone: true }"
                                  (click)="$event.stopPropagation()"
                                />
                              </div>
                            </div>
                            <div class="row mx-2 mt-2">
                              <div
                                class="col-sm-4"
                                style="display: flex; align-items: center"
                              >
                                <label class="m-0">Target Value</label>
                              </div>
                              <div class="col-sm-8">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Number"
                                  [(ngModel)]="mosInputTarget"
                                  style="font-size: 12px"
                                  [ngModelOptions]="{ standalone: true }"
                                  (click)="$event.stopPropagation()"
                                />
                              </div>
                            </div>
                            <div class="row mx-2 mt-2">
                              <div
                                class="col-sm-4"
                                style="display: flex; align-items: center"
                              >
                                <label class="m-0">Formula</label>
                              </div>
                              <div class="col-sm-8">
                                <select
                                  type="text"
                                  class="form-control"
                                  [(ngModel)]="calculation_mos"
                                  style="font-size: 12px"
                                  [ngModelOptions]="{ standalone: true }"
                                  (click)="$event.stopPropagation()"
                                >
                                  <option value="">--Select Formula--</option>
                                  <option
                                    *ngFor="let formula of formulaList"
                                    [value]="formula?.id"
                                  >
                                    {{ formula?.display_name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="row mx-2 mt-2">
                              <div
                                class="col-sm-4"
                                style="display: flex; align-items: center"
                              >
                                <label class="m-0">Unit</label>
                              </div>
                              <div class="col-sm-8">
                                <select
                                  type="text"
                                  class="form-control"
                                  [(ngModel)]="unit"
                                  style="font-size: 12px"
                                  [ngModelOptions]="{ standalone: true }"
                                  (click)="$event.stopPropagation()"
                                >
                                  <option value="">--Select Unit--</option>
                                  <option
                                    *ngFor="let unit of unitList"
                                    [value]="unit?.id"
                                  >
                                    {{ unit?.display_name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div
                              style="display: flex; justify-content: center"
                              class="p-1"
                            >
                              <button
                                (click)="createMos(i, j, k)"
                                mat-flat-button
                                color="primary"
                              >
                                <span *ngIf="editItem"> Update </span>
                                <span *ngIf="!editItem"> Create </span>
                                MOS
                              </button>
                            </div>
                          </div>
                        </div>
                      </mat-menu>
                      <div class="row mt-2">
                        <div
                          class="col-md-4 ml-auto"
                          style="
                            display: flex;
                            align-item: center;
                            justify-content: end;
                          "
                        >
                          <button
                            (click)="cancel(i, j, k)"
                            mat-stroked-button
                            color="primary"
                            class="mx-1"
                          >
                            Cancel
                          </button>
                          <button
                            (click)="update(i, j, k)"
                            mat-flat-button
                            mat-button
                            class="bg-primary text-white mx-1"
                            color="primary"
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a class="add-btton" (click)="addTermsItems(i, j)">
                    <mat-icon
                      aria-hidden="false"
                      aria-label="material-icons-filled"
                      class="cursor-pointer fs-5"
                      >add
                    </mat-icon>
                    Add Terms
                  </a>
                </div>
              </div>
            </div>
            <a class="add-btton" (click)="addSubcategoryItems(i)">
              <mat-icon
                aria-hidden="false"
                aria-label="material-icons-filled"
                class="cursor-pointer fs-5"
                >add
              </mat-icon>
              Add Sub Category
            </a>
          </div>
        </div>
      </div>
    </div>
    <a class="add-btton" (click)="addCategoryItems()">
      <mat-icon
        aria-hidden="false"
        aria-label="material-icons-filled"
        class="cursor-pointer fs-5"
        >add
      </mat-icon>
      Add Category
    </a>
  </div>
</form>
<div class="row mt-2">
  <div class="col-md-6"></div>
  <div class="col-md-6 text-right">
    <button
      mat-button
      mat-flat-button
      color="primary"
      class="fs-16 bg-primary text-white"
      (click)="saveKeyTerms()"
    >
      Save
    </button>
  </div>
</div>
